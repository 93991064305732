.main-menu-logo {
    width: 7.5rem;
}
@media (min-width: 768px) {
    .main-menu-logo {
        width: 7rem;
    }
}
@media (min-width: 900px) {
    .main-menu-logo {
        width: 8rem;
    }
}

.container-min-height {
    min-height: 95.4%;
}

.ant-form-item-label {
  line-height: 1;
  /* margin-top: 12px;
  margin-bottom: 4px; */
}
.ant-form-item-label label {
  font-size: .7rem;
  font-weight: 400;
}

.sidebar-menu {
  transition: all .2s ease-in-out;
  border-radius: 10px;
  background: white;
}
.sidebar-menu:hover, .sidebar-menu-active {
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
}
.sidebar-menu {
  color: black;
}
.sidebar-menu:hover, .sidebar-menu-active {
  color: white;
}
.sidebar-menu:hover > div > div:last-child, .sidebar-menu-active > div > div:last-child {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(168deg) brightness(104%) contrast(10000%);
}
.sidebar-menu > div:nth-child(1) > div:nth-child(1) {
  background-color: #EDEDED;
  padding: 6px;
  border-radius: 50%;
  box-shadow: inset 0px 3px 0px #293d3b1a;
}
.sidebar-menu > div:nth-child(1) > div:nth-child(1) > span {
  filter: invert(55%) sepia(0%) saturate(3482%) hue-rotate(63deg) brightness(129%) contrast(81%);
}
.sidebar-menu:not(:last-child) {
    margin-bottom: 10px;
}

.sidebar-menu .parents, .sidebar-menu .child div {
    cursor: pointer;
    font-size: 13px;
}
.sidebar-menu .child  {
    -webkit-animation: fadeHeight .6s;
    animation: fadeHeight .6s;
    overflow: hidden;
}

/* latest design */
.sidebar-menu-2 {
  transition: all .2s ease-in-out;
  background: white;
  border-bottom: solid 1px #EDEDED;
}
.sidebar-menu-2:hover, .sidebar-menu-2-active {
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
}
.sidebar-menu-2 {
  color: black;
}
.sidebar-menu-2:hover, .sidebar-menu-2-active {
  color: white;
}
/* .sidebar-menu-2:hover > div > div:last-child, .sidebar-menu-2-active > div > div:last-child {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(168deg) brightness(104%) contrast(10000%);
} */
.sidebar-menu-2 > div:nth-child(1) > div:nth-child(1) {
  padding: 3px;
}
.sidebar-menu-2 .parents, .sidebar-menu-2 .child div {
    cursor: pointer;
    font-size: 13px;
}
.sidebar-menu-2 .child  {
    -webkit-animation: fadeHeight .6s;
    animation: fadeHeight .6s;
    overflow: hidden;
}
.side-menu-2-country {
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
}
.side-menu-2-country:not(:last-child)::after {
  content: "";
  width: 90%;
  position: absolute;
  bottom: 0;
  display: block;
  border-bottom: solid 1px #EDEDED;
}
.side-menu-2-country:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, .6);
}
/* latest design */


.sidebar-menu-back, .sidebar-menu-back-open {
  transition: all .2s ease-in-out;
}
.sidebar-menu-back:hover {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(168deg) brightness(104%) contrast(102%);
}

.sidebar-menu-back-open {
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
}
.sidebar-menu-back-open > span {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(333deg) brightness(102%) contrast(102%);
} 
.sidebar-menu-back-open:hover {
  background: white;
}
.sidebar-menu-back-open:hover > span {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(6deg) brightness(20%) contrast(118%);
}

.inner-shadow {
  box-shadow: inset 0px 3px 0px #293d3b1a;
}

@keyframes fadeHeight {
    from { 
        height: 0;
    }
      to { 
        height: 140px;
      }
  }

.button-login {
  transition: all .2s ease-in-out;
  box-shadow: inset 0px 2px 0px #293d3b1a, 0px 4px 4px #0000001a;
  border: none;
  border-radius: 100px;
  background: white;
}
.button-login p, .button-login span {
  color: black;
}
.button-login:hover {
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
}
.button-login:hover > p, .button-login:hover > span, .button-login:hover > div > span {
  color: white;
}
.button-login div div {
  transition: all .2s ease-in-out;
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
  padding: 6px;
  border-radius: 50%;
}
.button-login:hover div div {
  background: #EDEDED;
  box-shadow: inset 0px 3px 0px #293d3b1a;
}
.button-login:hover div div span {
  filter: invert(55%) sepia(0%) saturate(3482%) hue-rotate(63deg) brightness(129%) contrast(81%);
}

.button-register {
  transition: all .2s ease-in-out;
  box-shadow: inset 0px 2px 0px #293d3b1a, 0px 4px 4px #0000001a;
  border: none;
  border-radius: 100px;
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);

}
.button-register > div > p, .button-register > div > span {
  color: white;
}
.button-register:hover {
  background: white;
}
.button-register:hover > div > p, .button-register:hover > div > span {
  color: black;
}

.button-default {
  transition: all .2s ease-in-out;
  border: solid #2C2D31 2px;
  background: #2C2D31;
}
.button-default p, .button-default span {
  color: white;
}
.button-default:hover {
  background: white;
}
.button-default:hover > p, .button-default:hover > span {
  color: black;
}

.button-primary {
  transition: all .2s ease-in-out;
  border: solid #28AE60 2px;
  background: #28AE60;
}
.button-primary p, .button-primary span {
  color: white;
}
.button-primary:hover {
  background: white;
}
.button-primary:hover > p, .button-primary:hover > span {
  color: black;
}

.button-secondary {
  transition: all .2s ease-in-out;
  border: solid #28AE60 2px;
  background: white;
}
.button-secondary p, .button-secondary span {
  color: black;
}
.button-secondary:hover {
  background: #28AE60;
}
.button-secondary:hover > p, .button-secondary:hover > span {
  color: white;
}

.button-secondary-transparent {
  transition: all .2s ease-in-out;
  border: solid #28AE60 2px;
  background: transparent;
}
.button-secondary-transparent p, .button-secondary-transparent span {
  color: #28AE60;
}
.button-secondary-transparent:hover {
  background: #28AE60;
}
.button-secondary-transparent:hover > p, .button-secondary-transparent:hover > span {
  color: white;
}
.button-secondary-transparent:hover .icon {
  filter: invert(0%) sepia(4%) saturate(7447%) hue-rotate(191deg) brightness(1000%) contrast(100%);
}

.button-nav-size {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}
@media (min-width: 768px) {
    .button-nav-size {
        width: 120px;
    }
}

.input-nav-size {
    width: 160px;
}

.select-primary {
    background-color: white;
    color: black;
    padding-left: 12px;
    padding-right: 12px;
    border: none;
    appearance: none;
    background-image: url("/dropdown-arrow.png");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.input-primary {
    background: #EDEDED;
    color: black;
    padding-left: 14px;
    padding-right: 12px;
    border: none;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
}

.search-input .ant-input-wrapper.ant-input-group {
  background-color: white;
  border-radius: 24px;
  overflow: hidden;
}
.search-input input{
  background-color: white;
  padding-left: 12px;
}
.search-input input:focus{
  outline: none;
}
.search-input .ant-input-group-addon > button {
  background-color: #28AE60;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-input .ant-input-group-addon > button svg {
  filter: invert(90%) sepia(99%) saturate(29%) hue-rotate(195deg) brightness(107%) contrast(99%);
}

.search-input .ant-input-group-addon > button {
  transition: all .2s ease-in-out;
  border: solid #28AE60 2px;
  background: #28AE60;
}
.search-input .ant-input-group-addon > button:hover {
  background: white;
}
.search-input .ant-input-group-addon > button:hover svg {
  filter: unset;
}

.bg-white-addonBefore input {
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;;
}
.bg-white-addonBefore input:focus{
  outline: none;
}
.footer-ul div {
    color: #F29D1F;
}

.footer-url {
    color: #F29D1F;
}

.footer-intro-header {
    color: #F29D1F;
    font-weight: 700;
    margin-top: 0.5rem; /* 8px */
    margin-bottom: 0.5rem; /* 8px */
}

.footer-intro-paragraph {
    margin-bottom: 0.7rem; /* 8px */
}

.footer-intro-last-paragraph {
    margin-bottom: 1rem; /* 8px */
}

.footer-intro-paragraph > a{
    color: #F29D1F;
    text-decoration-line: underline;
    cursor: pointer;

}

.footer-intro-paragraph > span{
  color: #F29D1F;
  text-decoration-line: underline;
  cursor: pointer;

}

.footer-intro-last-paragraph > a{
    color: #F29D1F;
    text-decoration-line: underline;
    cursor: pointer;
}


@media (max-width: 768px) {
    .footer-intro-header {
        color: #F29D1F;
        font-weight: 900;
        margin-top: 0.5rem; /* 8px */
        margin-bottom: 0.5rem; /* 8px */
        text-align: start;
        font-size: 1rem; /* 16px */
line-height: 1.5rem; /* 24px */
    }
    
    .footer-intro-paragraph {
        margin-bottom: 0.7rem; /* 8px */
        text-align: start;
    }
    
    .footer-intro-last-paragraph {
        margin-bottom: 1rem; /* 8px */
        text-align: start;
    }
}

.border-inputborder {
  transition: all .2s ease-in-out;
  border: solid 2px #28AE60 !important;
}
.border-inputborder:hover, .border-inputborder-active {
  background-color: #28AE60 !important;
  color: white !important;
}
.border-inputborder-secondary {
  transition: all .2s ease-in-out;
  background-color: #28AE60 !important;
  border: solid 2px #28AE60 !important;
}
.border-inputborder-secondary:hover {
  background-color: rgba(0, 0, 0, .2) !important;
}

.carousel .control-dots {
    text-align: left;
    padding-left: 18px;
    margin-bottom: 4px;
    margin-left: 10px;
}
@media (max-width: 768px) {
    .carousel .control-dots {
        margin-left: 5px;
    }
}

.carousel .control-dots .dot {
    margin: 0 2px;
}

.provider-slider-wrap {
    /* display: flex; */
    flex-wrap: nowrap;
    overflow-x: auto;
}

.provider-slider {
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
}

.provider-slider span {
    font-size: 11px;
}

.home-top-banner-wrap {
    position: relative;
    width: 100%;
}

.home-top-banner-wrap .banner_wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 106%;
    /* width: calc(100% + 40px); */
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;

    animation-name: banners-move-bg;
    animation-duration: 30s;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    object-position: 50% 50%;
    right: -20px;
    left: -20px;
}

.home-top-banner-wrap .banner_wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-position: inherit;
    object-position: inherit;
    border-radius: inherit;
    object-fit: cover;
}

.home-top-banner-wrap .layer1_wrap {
    position: absolute;
    right: auto;
    left: 10%;
    bottom: 0;

    animation-name: banners-move-front;
    animation-duration: 30s;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.home-top-banner-wrap .banner_wrap span img,
.home-top-banner-wrap .layer1_wrap span img,
.home-top-banner-wrap .layer2_wrap span img,
.home-top-banner-wrap .layer3_wrap span img{
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
}

.home-top-banner-wrap .banner_wrap span, 
.home-top-banner-wrap .layer1_wrap span, 
.home-top-banner-wrap .layer2_wrap span, 
.home-top-banner-wrap .layer3_wrap span {
    position: relative !important;
}

.home-top-banner-wrap .layer2_wrap {
    position: absolute;
    right: auto;
    left: 10%;
    bottom: 0;

    animation-name: banners-move-front-2;
    animation-duration: 30s;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.home-top-banner-wrap .layer3_wrap {
    position: absolute;
    /* right: auto; */
    left: auto;
    bottom: -7.5%;
}

@keyframes banners-move-bg {
    0% {
        transform: translateZ(0) rotate(.0001deg);
    }

    25% {
        transform: translate3d(-20px, 0, 0) rotate(.0001deg);
    }

    50% {
        transform: translateZ(0) rotate(.0001deg);
    }

    75% {
        transform: translate3d(20px, 0, 0) rotate(.0001deg);
    }

    100% {
        transform: translateZ(0) rotate(.0001deg);
    }
}

@keyframes banners-move-front {
    0% {
        transform: translateZ(0) rotate(.0001deg);
    }

    25% {
        transform: translate3d(10px, 0, 0) rotate(.0001deg);
    }

    50% {
        transform: translateZ(0) rotate(.0001deg);
    }

    75% {
        transform: translate3d(-6px, 0, 0) rotate(.0001deg);
    }

    100% {
        transform: translateZ(0) rotate(.0001deg);
    }
}

@keyframes banners-move-front-2 {
    0% {
        transform: translateZ(0) rotate(.0001deg);
    }

    25% {
        transform: translate3d(-10px, 0, 0) rotate(.0001deg);
    }

    50% {
        transform: translateZ(0) rotate(.0001deg);
    }

    75% {
        transform: translate3d(6px, 0, 0) rotate(.0001deg);
    }

    100% {
        transform: translateZ(0) rotate(.0001deg);
    }
}

@media (min-width: 768px) {
  .menu-faq:hover, .menu-faq-active {
    background: #28AE60;
  }
  .menu-faq:hover p, .menu-faq:hover span, .menu-faq-active p, .menu-faq-active span {
    color: white;
  }
}

.selectedLive {
    cursor: pointer;
    padding: 8px;
    margin-bottom: 4px;
}
.selectedLive.active {
    background-color: #425C76;
    border-radius: 8px;
}


.home-carousel .control-dots {
    /* text-align: right !important; */
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .home-carousel .control-dots li.dot {
    margin: 0 2px !important;
    width: 6px;
    height: 6px;
    border-radius: 12px !important;
    background-color: #BFBFBF !important;
    opacity: 1 !important;
    box-shadow: none !important;
  }
  .home-carousel .control-dots li.dot.selected {
    background-color: #F29D1F !important;
  }

  .home-sport-carousel button.control-arrow.control-prev,
  .home-sport-carousel button.control-arrow.control-next {
    background-color: white;
    z-index: 10;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    margin: 0;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-carousel-buttons {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    z-index: 10;
    display: none;
  }
  @media (min-width: 768px) {
    .custom-carousel-buttons {
      display: block;
    }
  }
  .custom-carousel-buttons button.control-arrow.control-prev {
    left: -20px;
  }
  .custom-carousel-buttons button.control-arrow.control-next {
    right: -20px;
  }

  .navbar-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    height: 60px;
    z-index: 99;
    border-top: 2px solid #28AE60;
  }
  .navbar-child {
    /* position: absolute;
    bottom: 8px; */
    width: 100%;
    /* left: 0; */
    height: 100%;
  }
  .navbar-child > div {
    cursor: pointer;
    /* display: flex;
    flex-direction: column; */
  }
  .navbar-child div > div {
    /* width: 20px; */
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .navbar-child div.active > div {
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -4px;
  } */
  .navbar-child div p {
    font-size: 11px;
    font-weight: 500;
  }
  /* .navbar-child div:nth-child(3) > div {
    width: 75px;
    margin-left: -7px;
    margin-bottom: -7px;
  } */

  .mobile-menu {
    background-color: transparent ;
  }
    
    /* .home-hotgame-wrap {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
    } */

    /* .home-hotgame {
        flex: 0 0 auto;
        width: 175px;
    } */

  /* .home-hotgame-wrap > div {
    display: inline-block;
  } */
  .home-hotgame-wrap > div:nth-child(1) {
    width: 29%;
  }
  /* .home-hotgame-wrap > div:nth-child(2) {
    width: 75%;
  } */
  @media (min-width: 768px) {
    .home-hotgame-wrap > div:nth-child(2) {
        width: 74.6%;
    }
}
.hotgame-carousel .thumbs-wrapper {
    margin: 0;
}
.hotgame-carousel .control-dots {
    margin: 0 10px 2px 10px;
}

/* more samples */
/* https://cssloaders.github.io/ */
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #F29D1F;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}
  .loader-bottom {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #F29D1F;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 
    
.games-container {
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: bottom;
}

.pincode-input-container {
    display: flex;
    justify-content: center;
}

.livechat-custom {
    max-width: 98%;
    width: 98%;
    max-height: 600px;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}

@media (min-width: 768px) {
    .livechat-custom {
        max-width: 400px;
        width: 400px;
        max-height: 600px;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
    }   
}

.livechat-custom-landscape {
    max-width: 98%;
    width: 50%;
    max-height: 600px;
    height: 90%;
    border-radius: 8px;
    overflow: hidden;
}

.cut-text-soccer { 
    text-overflow: ellipsis;
    overflow: hidden; 
    /* width: 74px;  */
    height: 1.3em; 
    white-space: nowrap;
    margin: auto;
  }

  .cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 50px; 
    height: 1.2em; 
    white-space: nowrap;
    margin: auto;
  }

  @media (min-width: 400px) {
    .cut-text { 
        width: 70px; 
      }
  }

  @media (min-width: 500px) {
    .cut-text { 
        width: 80px; 
      }
  }

  @media (min-width: 768px) {
    .cut-text { 
        width: 70px; 
      }
  }

  @media (min-width: 1000px) {
    .cut-text { 
        width: 90px; 
      }
  }

  .cut-text-game {
    text-overflow: ellipsis;
    overflow: hidden; 
    max-width: 90%; 
    /* height: 1.2em;  */
    white-space: nowrap;
    /* margin: auto; */
  }
  /* @media (min-width: 400px) {
    .cut-text-game { 
        width: 80px; 
      }
  }
  @media (min-width: 500px) {
    .cut-text-game { 
        width: 100px; 
      }
  }
  @media (min-width: 768px) {
    .cut-text-game { 
        width: 80px; 
      }
  }
  @media (min-width: 1000px) {
    .cut-text-game { 
        width: 90px; 
      }
  } */

  .cut-text-full {
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 100%; 
    height: 1.3em; 
    white-space: nowrap;
    margin: auto;
  }

  .cut-text-sport-match {
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 90%; 
    display: inline-block;
    height: 1.3em; 
    white-space: nowrap;
    margin: auto;
  }

  .collapsible {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    padding: 6px 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .collapsible > div > div > span {
    font-size: 12px;
    font-weight: 500;
  }

  .collapsible_Football {
    background: #0075FF;
  }
  .collapsible_Cricket {
    background: #8000FF;
  }
  .collapsible_Basketball {
    background: #FF9900;
  }
  
  .sport-badge span {
    font-size: 7px;
  }
  .sport-badge sup {
    padding: 0 0px;
    min-width: 14px;
    height: 14px;
    line-height: 1;
  }
  @media (min-width: 768px) {
    .sport-badge span {
        font-size: 10px;
    }
    .sport-badge sup {
    padding: 0 4px;
    }
  }
  .content {
    /* padding: 0 4px; */
    /* max-height: 0; */
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #1D262C;
    /* border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; */
  }
  .content p, 
  .content span, 
  .sport-head-title
  {
    font-size: 9px;
    font-weight: 400;
  }
  @media (min-width: 768px) {
    .content p, 
    .content span,
    .sport-head-title {
        font-size: 11px;
      } 
  }

  .flex-container {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes the full width of the viewport */
  }

  .flex-item {
    flex: 0 0 auto; /* Allow items to shrink or grow, but not shrink to less than their content */
    margin: 0 8px;
    width: 20px; /* Set a fixed width for each item */
  }

  @media (min-width: 768px) {
    .flex-item {
        width: 30px; 
      }
  }

  .flex-item-sport {
    flex: 0 0 auto; 
    width: 300px; 
  }
  .flex-item-sport:not(:last-child) {
    margin-right: 10px;
  }

  .flex-item-banner {
    flex: 0 0 auto; 
  
  }
  .flex-item-banner:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-banner {
      flex: 0 0 auto; 
      width: 461px; 
    }
    .flex-item-banner:not(:last-child) {
      margin-right: 0;
    }
  }

  .flex-item-matchbanner {
    flex: 0 0 auto; 
    width: 254px; 
  }
  .flex-item-matchbanner:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-matchbanner {
      width: 300px;
    }
  }

  .flex-item-playbanner {
    flex: 0 0 auto; 
    width: 123px; 
  }
  .flex-item-playbanner:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-playbanner {
      width: 146px;
    }
  }

  .flex-item-cat {
    flex: 0 0 auto; 
    width: 76px; 
  }
  .flex-item-cat:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-cat {
      flex: 0 0 auto; 
      width: 86px; 
    }
    .flex-item-cat:not(:last-child) {
      margin-right: 14px;
    }
  }

  .flex-item-game {
    flex: 0 0 auto; 
    width: 120px; 
  }
  .flex-item-game:not(:last-child) {
    margin-right: 10px;
  }
  @media (min-width: 768px) {
    .flex-item-game {
      width: 140px; 
    }
  }

  .flex-item-promotion {
    flex: 0 0 auto; 
    width: 80px; 
  }
  .flex-item-promotion:not(:last-child) {
    margin-right: 14px;
  }
  @media (min-width: 768px) {
    .flex-item-promotion {
      width: 140px; 
    }
  }

  .flex-item-provider {
    flex: 0 0 auto; 
    width: 150px; 
  } 
  .flex-item-provider:not(:last-child) {
    margin-right: 8px;
  }
  .flex-item-game-banner {
    flex: 0 0 auto; 
    width: 310px; 
  }
  .flex-item-game-banner:not(:last-child) {
    margin-right: 8px;
  }
  @media (min-width: 768px) {
    .flex-item-game-banner {
      width: 785px; 
    }
  }

  .game-provider-wrapper {
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  .game-provider-wrapper:hover {
    background: #28AE60;
    color: white;
  }
  .game-provider-wrapper-active {
    background: #28AE60;
    color: white;
  }

  .newDashboardLineDesktop span, .newDashboardLineDesktop span img {
    position: relative !important
  }

  .newDashboardLineDesktop span img {
    height: 1px !important;
    width: 100%;
  }

  .hotgames-mobile span, .hotgames-mobile span img {
    position: relative !important
  }

  .hotgames-mobile span img {
    height: 100% !important;
    width: 100% !important;
  }

  .home-popup-banner span, 
  .home-popup-banner span img, 
  .banner-right span, 
  .banner-right span img{
    position: relative !important 
  }

  .home-popup-banner span img, 
  .banner-right span img{
    height: 320px !important;
    width: 100% !important;
  }

  .right-banner-div {
    height: 320px !important;
  }

.profile-tab {
  transition: all .2s ease-in-out;
  background-color: white;
}
.profile-tab:hover {
  background-color: #28AE60;
  color: white;
}
.profile-tab.profile-active {
  background-color: #28AE60;
  color: white;
}
.profile-tab:hover img, .profile-active img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(168deg) brightness(1000%) contrast(100%);
}

.bank-tab {
  transition: all .2s ease-in-out;
  background-color: #28AE60;
  color: white;
  border: solid 2px #28AE60;
}
.bank-tab:hover {
  background-color: white;
  color: black;
}

.menu-get-bonus {
  background-color: #EDEDED;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.bonug-img {
  width: 17px;
  height: 17px;
}

.vip-tab {
  transition: all .2s ease-in-out;
  position: relative;
}
.vip-tab:hover::after, .vip-tab-active::after {
  content: "";
  background: linear-gradient(90deg, #61E58F 0%, #39B766 51.04%, #128B3E 100%);
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  border-radius: 4px;
}