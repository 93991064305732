@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-primary: #1A242E;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

html,
body,
div {
  scroll-behavior: smooth !important;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

input, textarea {
  background-color: #EDEDED;
}

#left-sider {
  transition: 0.15s all ease;
  /* padding-top: 60px; */
}

.leftSider-padding {
  transform: translateY(-58px);
}

#left-sider>div {
  position: relative;
}

.left-logo-wrap {
  position: relative;
}

.left-logo-wrap:first-child:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
  height: 100%;
  background: linear-gradient(134.82deg, rgb(24, 147, 68, .45) 1.27%, rgba(24, 147, 68, 0) 44.52%);
}

#header-navbar {
  position: sticky;
  width: 100%;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  transition: 0.15s all ease;
}

.header-navbar-hidden {
  transform: translateY(-58px);
}

* {
  box-sizing: border-box;
}

.container {
  /* max-width: 1920px; */
  /* min-width: 100%; */
  max-width: 1536px;
  margin: auto;
}

.container-lg {
  max-width: 2200px;
  margin: auto;
}

/* .shadow {
  box-shadow: inset 0px 2px 0px #293d3b1a, 0px 4px 4px #0000001a;
} */
.shadow-bottom {
  box-shadow: 0px 2px 0px 0px rgb(0 0 0 / 0.1);
}

.menu-responsive>div {
  margin-left: 0rem;
  margin-right: 0rem;
}

.menu-responsive>div.active,
.menu-responsive>div.menu-item:hover {
  filter: invert(55%) sepia(32%) saturate(885%) hue-rotate(92deg) brightness(93%) contrast(95%);
  /* color: #1B57F0; */
}

/* @media (min-width: 768px) {
  .menu-responsive > div {
    margin-left: 0rem;
    margin-right: 1.2rem;
  }
} */

@media (min-width: 768px) {
  .menu-responsive > div:nth-child(0):hover {
    color: #F29D1F !important;
  }
}

label,
p,
span {
  font-size: 12px;
  font-weight: 300;
}

.leading-unset {
  line-height: 0;
}

.mb-14 {
  margin-bottom: 4.5rem;
}

.mb-16 {
  margin-bottom: 3.5rem;
}

.mb-18 {
  margin-bottom: 6.5rem;
}

.mb-22 {
  margin-bottom: 8.5rem;
}

.-mt-18 {
  margin-top: -5rem;
}

.-mt-22 {
  margin-top: -7rem;
}

.gradient-red {
  background: linear-gradient(180deg, #C61D22 0%, #9F171A 100%);
}

ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
  display: flex;
  justify-content: flex-end;
}

ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right>li {
  cursor: pointer;
  font-size: 12px;
  padding: 12px;
}

ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right>li:last-child {
  padding-left: 0px
}

li.ant-pagination-item-ellipsis {
  margin-top: 2px
}

ul>li.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon>a>div>span.ant-pagination-item-ellipsis {
  margin-left: 5px;
  margin-top: 2px;
  position: absolute;
}

/* ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right>li.ant-pagination-item:nth-last-child(2) {
  margin-left: 25px !important
} */

ul>li.ant-pagination-prev.ant-pagination-disabled>button,
ul>li.ant-pagination-next>button {
  position: absolute;
  margin-top: -2.5px;
}

.ant-form-item-explain-error {
  color: #F29D1F
}


.btn-green {
  background: linear-gradient(180deg, #3AFFB8 0%, #015033 100%);
}

.btn-red {
  background: linear-gradient(180deg, #FE7C7C 0%, #970000 100%);
}

.btn-gold {
  background: linear-gradient(180deg, #FFCD78 0%, #935D00 100%);
}

.btn-blue {
  background: linear-gradient(180deg, #FFCD78 0%, #935D00 100%);
}

.btn {
  position: absolute;
  width: 38%;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.btn:active {
  margin-top: 2px !important;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.7);
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

p.bonus-title {
  /* width: 275px;
  height: 19px; */

  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 19px;

  background: linear-gradient(180deg, #FDEC97 0%, #B57E10 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.ant-progress-outer {
  border-radius: 15px;
  overflow: hidden;
}

.ant-progress-inner {
  background: #ffffff;
  border-radius: 100px;
}

.bg-white-progress-inner .ant-progress-inner {
  background: white;
  border-radius: 100px;
}
.bg-black-progress-inner .ant-progress-inner {
  background: rgba(19, 22, 29, .1);
  border-radius: 100px;
}

.ant-progress-bg {
  background: linear-gradient(180deg, #89FFB8 0%, #038F00 100%);
}

.live-deposit {
  background: #23AB5E;
}

.live-witdraw {
  background: #23AB5E;
}

.live-title {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;

  background: linear-gradient(180deg, #FDEC97 0%, #B57E10 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.home-btn {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* identical to box height */


  /* gold 2 */

  background: linear-gradient(180deg, #FDEC97 0%, #B57E10 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 1px 2px #919191;
}

/* menu styling */
.bm-menu-wrap {
  width: auto !important;
  padding-top: 40px !important;
  /* padding-left: 10px !important; */
  background-color: #EDEDED;
  /* opacity: 0.9; */
  overflow: hidden !important;
  z-index: 9999999999 !important;
}

.bm-item-list {
  overflow: hidden !important;
}

.bm-item {
  outline-width: 0px;
}

#chat-widget-container {
  bottom: 60px !important;
  max-height: 92% !important
}

.ant-table-content>table {
  width: 100%
}

.bg-custom {
  background-color: #EDEDED;
}

@media (min-width: 768px) {

  .footer-bg-custom {
    background: #1A242E
  }
}

@media (max-width: 768px) {
  .footer-bg-custom {
    background: #23333F
  }
}


.profile-qr-img>span {
  border-radius: 15px;
  box-shadow: rgb(255 255 255) 0px 3px 10px;
  overflow: hidden;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.ant-radio-hide label .ant-radio-button {
  display: none;
}

.ant-radio-button-wrapper-checked {
  /* --tw-border-opacity: 1;
  border-color: rgb(253 236 151 / var(--tw-border-opacity));
  border-width: 1px; */
  background-color: #EDEDED;
  border-radius: 0.5rem;
}

.ant-upload-span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
}

.ant-upload-span .ant-upload-text-icon,
.ant-upload-span .ant-upload-list-item-card-actions {
  font-size: 6px;
}

/* checkin */
.checkin_border::after {
  content: "";
  border: solid 3px rgb(168, 75, 30);
  border-right-width: 20px;
  position: absolute;
  top: 50%;
  transform: translate(10px, -50%);
  width: 62px;
}

.checkin_border-last-child::after {
  content: "";
  border: solid;
  border-right-width: 20px;
  border-color: rgb(168, 75, 30);
  border-width: 3px;
  position: absolute;
  top: 45px;
  transform: rotate(90deg);
  left: 0;
  right: 0;
}

.checkin_border_reverse_first::before {
  content: "";
  border: solid;
  border-right-width: 20px;
  border-color: rgb(168, 75, 30);
  border-width: 3px;
  position: absolute;
  top: 45px;
  transform: rotate(90deg);
  left: 0;
  right: 0;
}

.checkin_border_reverse::before {
  content: "";
  border: solid 3px rgb(168, 75, 30);
  border-right-width: 20px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 50px;
  left: -27px;
}

.checkin_border.checked::after,
.checkin_border_reverse.checked:before {
  border: solid 3px rgb(246, 230, 191) !important;
}

.checkin_border-last-child.checked::after,
.checkin_border_reverse_first.checked::before {
  border-color: rgb(246, 230, 191) !important;
}

.claimed-overlay {
  position: relative;
}

.claimed-overlay {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  border-radius: 0.5rem;
  z-index: 9;
}

.claimed-overlay span {
  color: white;
  transform: rotate(-26deg);
  position: absolute;
  font-size: 13px;
  font-weight: bold;
  left: 0;
  right: 0;
  top: 35%;
  margin: auto;
}


.game-overlay-wrap .game-overlay-img {
  overflow: hidden;
}

.game-overlay {
  display: none;
  -webkit-animation: gamefadeIn .5s;
  animation: gamefadeIn .5s;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 12, 36,.45); */
  left: 0;
  top: 0;
  z-index: 9;
}

.game-overlay-img img {
  transition: all .3s ease-in-out;
  /* position: absolute;
  top: 50%;
  transform: translate(0px, -50%); */
}

.game-overlay-wrap:hover .game-overlay-img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 12, 36, .75);
  opacity: .25;
  z-index: 1;
}

.game-overlay-wrap:hover .game-overlay-img img {
  transform: scale(1.2);
}

.game-overlay-wrap:hover .game-overlay-text {
  background-color: #22A35F;
  color: white;
}

@keyframes gamefadeIn {
  from {
    margin-top: 100%;
  }

  to {
    margin-top: 0%;
  }
}


.game-banner-button {
  transition: all .3s ease-in-out;
  opacity: 0;
  z-index: 9;
  background-color: #22A35F;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.game-overlay-wrap:hover .game-overlay {
  display: block;
}

.game-banner-wrap:hover .game-banner-button {
  opacity: 1;
}

.game-overlay button {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 85%;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);
}

.game-banner-button {
  transition: all .3s ease-in-out;
  opacity: 0;
  z-index: 9;
  background-color: #22A35F;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.game-banner-wrap:hover>.game-banner-button {
  opacity: 1;
}

.game-overlay button {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 85%;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);
}

.home-provider {
  position: relative;
  overflow: hidden;
}

.home-provider-child {
  position: absolute;
  top: 0;
  transition: all .4s ease-in-out;
}

.home-provider:hover>.home-provider-child {
  /* -webkit-animation: providerfadeIn .6s;
  animation: providerfadeIn .6s; */
  top: -100%;
}

@keyframes providerfadeIn {
  from {
    top: 0%;
  }

  to {
    top: -100%;
  }
}

.ant-menu {
  color: white !important;
  font-weight: 600;
}

.ant-menu li span,
.ant-menu-submenu li span {
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  min-width: 160px;
  z-index: 1;
  background-color: white;
  padding: .3rem .8rem;
}
.dropdown-content .menu-item:hover {
  filter: invert(55%) sepia(32%) saturate(885%) hue-rotate(92deg) brightness(93%) contrast(95%);
  /* color: #1B57F0; */
}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* @-webkit-keyframes fadeIn {
  from { margin-top: -20%; }
    to { margin-top: 0%; }
} */
@keyframes fadeIn {
  from {
    margin-top: -20%;
    z-index: -1;
  }

  to {
    margin-top: 0%;
    z-index: 10;
  }
}

table {
  border-radius: 4px;
  overflow: hidden;
}

.ant-table-thead {
  background: rgb(246,246,246);
  color: black;
}

.ant-table-thead th {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 13px;
}

.ant-table-thead th:first-child {
  padding-left: 6px;
}

.ant-table-thead th:last-child {
  padding-right: 6px;
}

.ant-table-row td {
  padding-top: 8px;
  padding-bottom: 10px;
  font-weight: 400;
}

.ant-table-row td div {
  font-weight: 400;
}

.ant-table-row {
  border-bottom: solid 1px;
  border-color: rgb(238, 238, 238);
}

.ant-table-tbody {
  text-align: center;
  background: white;
}

.ant-pagination {
  padding-left: 12px;
  padding-right: 12px;
  background: #EDEDED;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.modern-react-captcha {
  display: flex;
  /* gap: 15px; */
  cursor: default;
  margin-top: 5px;
}

.modern-react-captcha__reloadBtn {
  margin-right: 10px;
}

.modern-react-captcha__captchaText {
  border-radius: 0.75rem;
}

.modern-react-captcha__inputField {
  border-radius: 0.75rem;
  padding: 5px;
  width: 100%;
}

::-webkit-scrollbar {
  background-color: #1A242E;
  width: 8px;
  height: 6px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #1A242E;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 16px;
  border: none;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.text-one-line-box {
  display: -webkit-box;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  text-transform: uppercase;
  /* margin-bottom: 15px; */
}

.card {
  height: 300px;
  width: 200px;
  margin: 10px;
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow: 0px 0px 10px 2px rgb(200,200,200); */
  overflow: hidden;
  background-size: cover;
}

.skeleton-gray {
  background: transparent;
  background-image: linear-gradient(115deg, #212D38 20%, #273645 30%, #273645 35%, #212D38 40%);
  background-position: 0% 0;
  background-size: 300% 200%;
  color: rgba(0, 0, 0, 0);
  animation: shimmer 1500ms infinite;
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }

  60%,
  100% {
    background-position: -50% 0;
  }
}

.hot-game-wrapper div:nth-child(11),
.hot-game-wrapper div:nth-child(12),
.hot-game-wrapper div:nth-child(13),
.hot-game-wrapper div:nth-child(14) {
  display: block;
}

@media (min-width: 768px) {
  .hot-game-wrapper div:nth-child(11),
  .hot-game-wrapper div:nth-child(12),
  .hot-game-wrapper div:nth-child(13),
  .hot-game-wrapper div:nth-child(14) {
    display: none;
  }
}

input, select, option {
  font-size: 12px;
}
.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-input-search .ant-input-wrapper.ant-input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-input-search input {
  width: 100%;
  padding: 0.4rem 0;
}

.ant-input-search button {
  color: black;
}

.ant-input-search button span {
  font-size: 16px;
  font-weight: bold;
}

.campaign-pool-wrapper {
  animation-name: floating_left;
  animation-duration: 2s;
  left: 0;
}

@keyframes floating_left {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 0%;
  }
}

.help-center-wrapper {
  transition: .55s all ease;
  right: -164px;
}

@media (min-width: 768px) {
  .help-center-wrapper {
    transition: .55s all ease;
    right: -203px;
  }
  
  .help-center-wrapper:hover {
    right: 0;
  }
}

.help-center-overlay-closeable {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.defaultImage, .hoverImage {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
}

.hoverImage {
  opacity: 0;
}

.relative:hover .defaultImage {
  opacity: 0;
}

.relative:hover .hoverImage {
  opacity: 1;
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.chevron-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust right value to position the icon */
  transform: translateY(-50%);
  pointer-events: none; /* Ensures the icon doesn't interfere with clicking on the select */
  display: flex;
}

table.betAmountTable {
  font-size: 0.8rem;
  text-align: center;
  background-color: #f4cccc;
  margin-top: 5px;
  margin-bottom: 5px;
}

th.betAmoutTitle,
td.betAmoutText {
  border: 2px solid rgb(0, 0, 0);
  padding: 4px 50px;
}

th.betAmoutTitle {
  background-color: #f4cccc;
  color: #000000;
  cursor: pointer;
  font-weight: bold;
}

.jodit_wysiwyg th,
.jodit_wysiwyg td {
  border: 1px solid #000000;
}

.aviator-scrollbar-color {
    scrollbar-color: #DBDBDB #F5F5F5;
}