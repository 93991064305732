.modal-primary {
  display: none;
}

.modal-primary {
  /* padding-top: 48px; */
  padding-bottom: 48px;
  padding-left: 12px;
  padding-right: 12px;
}

.modal-primary.show {
  display: block;
}

.modal-video {
  padding: 0 !important;
}
.modal-dialog-video {
  margin: 0 !important;
  max-width: 100% !important;
}
/* .modal-primary.show .modal-dialog {
  transform: translate(0,0);
} */

.modal-dialog {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  -webkit-animation: modalfadeIn .6s;
  animation: modalfadeIn .6s;
  background-color: white;
}

.close-button {
  border: solid 1px #EDEDED;
  background-color: #787878;
}
.close-button:hover {
  opacity: 0.95;
  background-color: #EDEDED;
}

.button-signup {
  transition: all .2s ease-in-out;
  background-color: #28AE60 !important;
  border: solid 2px #28AE60 !important;
}
.button-signup:hover {
  background-color: transparent !important;
}
.button-signup:hover > span {
  color: #8F9196 !important;
}

@keyframes modalfadeIn {
  from {
    margin-top: -20%;
  }

  to {
    margin-top: 1.75rem;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin-top: 1.75rem;
    animation: modalfadeIn .6s;
  }
  .modal-dialog.wide {
    max-width: 700px;
    animation: modalfadeIn .6s;
  }
  .modal-dialog.wide-xl {
    max-width: 900px;
    animation: modalfadeIn .6s;
  }
}

.overlay-closeable {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .4);
}